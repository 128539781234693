import React, { useEffect, useState } from "react";

const Participants = ({ setView }) => {
  const [data, setData] = useState([]);

  const getParticipants = async () => {
    return fetch("https://6391cedaac688bbe4c53360a.mockapi.io/newline")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Fetched data:", data);
        setData(data);

        return data;
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  };

  useEffect(() => {
    getParticipants();
  }, []);

  return (
    <div className="rounded-lg text-black bg-white py-8 px-4 min-w-96 md:min-w-[35rem] text-center">
      <h1 className="text-xl font-semibold tracking-wider mb-4">
        REGISTERED PARTICIPANTS
      </h1>
      {data?.length <= 32 && (
        <button
          onClick={() => setView(true)}
          className="text-sm py-1 px-3 mb-4 shadow-md border border-solid italic my-2 hover:text-red-600"
        >
          Click to register
        </button>
      )}

      <div className="mt-4">
        {data?.length === 0 ? (
          <div className="text-sm italic mt-4">No participant yet!</div>
        ) : (
          <table className="w-full">
            <thead className=" w-full">
              <tr className="pb-4">
                {/* <th>Game</th> */}
                {/* <th>Player 1</th> */}
                <th></th>
                <th>Gamer</th>
                {/* <th>Player 2</th> */}
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => {
                return (
                  // index % 2 === 0 && (
                  <tr className=" font-normal my-2 py-2 text-gray-600">
                    {/* <td> {index === 0 ? 1 : index} </td> */}
                    <td> {index + 1} </td>
                    <td> {item?.name} </td>
                    {/* <td> {data[index + 1]?.name} </td> */}
                  </tr>
                  // )
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Participants;
