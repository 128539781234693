import React, { useState } from "react";
import ggg from "../assets/ggg.jpeg";
import Participants from "./Participants";
import { useParams } from "react-router-dom";

const RegisterForTournament = () => {
  const [FormData, setFormData] = useState({
    name: "",
    phone: "",
  });

  const [state, setState] = useState(null);

  // const params = useParams();

  // console.log(pa)

  const handleSubmit = async () => {
    return await fetch("https://6391cedaac688bbe4c53360a.mockapi.io/newline", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(FormData), // body data type must match "Content-Type" header
    })
      .then((response) => {
        if (!response.ok) {
          setState(false);
          throw new Error("Network response was not ok");
        }
        setState(true);
        setFormData({
          name: "",
          phone: "",
        });
        return response.json();
      })
      .catch((error) => {
        setState(false);
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  };

  const [view, setView] = useState(true);

  return (
    <div
      className="text-white flex items-center p-4 justify-center w-screen min-h-screen
  "
    >
      {view ? (
        <div className="rounded-lg text-black bg-white py-8 px-4">
          <div className="my-12 text-center">
            <h1 className=" text-2xl font-bold text-red-600 sm:text-4xl mb-6">
              Gudlvn Gaming Lounge
            </h1>
            <h1 className=" text-4xl font-semibold sm:text-6xl mb-6">
              FC 24 Tournament
            </h1>

            <div className="w-full max-w-xl mx-auto">
              <div className="p-4 bg-white rounded-xl shadow-md pb-12 text-black w-full">
                <div className="flex items-center justify-center">
                  <img
                    src={ggg}
                    alt=""
                    className=" object-cover rounded-full w-44 h-44"
                  />
                </div>
                <p className="text-lg uppercase font-semibold mt-6 text-center">
                  Register Below!
                </p>

                <div className="pt-6 px-4  mt-4">
                  <div>
                    <p className="text-start text-[12px] ml-2 mb-1 text-gray-500">
                      Full Name
                    </p>
                    <input
                      value={FormData?.name}
                      placeholder="Your name..."
                      className="w-full border-gray-500 outline-none border border-solid p-2 rounded-lg text-sm text-gray-600"
                      onChange={(e) =>
                        setFormData({ ...FormData, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="mt-6">
                    <p className="text-start text-[12px] ml-2 mb-1 text-gray-500">
                      Telephone
                    </p>
                    <input
                      value={FormData?.phone}
                      placeholder="Your contact..."
                      className="w-full border-gray-500 outline-none border border-solid p-2 rounded-lg text-sm text-gray-600"
                      onChange={(e) =>
                        setFormData({ ...FormData, phone: e.target.value })
                      }
                    />
                  </div>
                  <div className="my-4">
                    <p
                      className={`text-sm italic ${
                        state ? "text-green-600" : "text-red-600"
                      }`}
                    >
                      {state === true
                        ? "You have been added to the tournament!"
                        : state === false && "Failed to Add participant!"}
                    </p>
                  </div>
                  <div className="flex items-center justify-end">
                    <button
                      onClick={handleSubmit}
                      className="py-2 px-12 bg-red-600 text-white mt-6 rounded-lg"
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <button
                  onClick={() => setView(false)}
                  className="text-sm py-1 px-3 shadow-md border border-solid italic my-2 hover:text-red-600"
                >
                  View all Participants
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Participants setView={setView} />
      )}
    </div>
  );
};

export default RegisterForTournament;
