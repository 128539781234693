import React, { useState, useEffect, useRef } from "react";
import img1 from "../assets/banner_img.png";
import img3 from "../assets/live_stareams_bg.png";
import img4 from "../assets/event_bg.png";
import vid1 from "../assets/vid1.mp4";
import vid2 from "../assets/vid2.mp4";
import pic1 from "../assets/pic1.jpeg";
import ggg from "../assets/ggg.jpeg";
import mmm from "../assets/mmm.jpeg";
import pic2 from "../assets/pic2.jpeg";

const Home = () => {
  const targetDate = new Date("September 1, 2024 00:00:00").getTime();

  // State to hold the time left
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const [play, setPlay] = useState(false);

  const vidRef = useRef(null);

  const togglePlay = () => {
    if (play) {
      vidRef.current.pause();
    } else {
      vidRef.current.play();
    }

    setPlay(!play);
  };

  // Function to calculate the time left until the target date
  function calculateTimeLeft() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    let time = {};
    if (difference > 0) {
      time = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    } else {
      // If the target date is in the past, reset everything to 0
      time = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return time;
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(timer);
  }, []);

  return (
    <div>
      <div className="grid md:grid-cols-2 h-screen w-screen items-center justify-center">
        <div className="col-span-1">
          {/* <img src={img1} alt="" className="absolute object-cover" /> */}
          <div className="w-full h-full flex items-center gap-12 text-start md:justify-end justify-center text-white p-4">
            <div>
              <h1 className="md:text-6xl text-4xl font-semibold">
                Gudlvn Gaming Lounge
              </h1>
              <p className="md:text-lg mt-2">
                Welcome to the community of gamerz!
              </p>
              <div className="mt-6 flex gap-4 text-sm max-w-2xl flex-wrap">
                <div className="flex items-center justify-start gap-4">
                  <div className="h-4 w-4 bg-white rounded-full flex items-center justify-center">
                    <div className="w-3 h-3 rounded-full bg-black"> </div>
                  </div>
                  <p> Action Games </p>
                </div>
                <div className="flex items-center justify-start gap-4">
                  <div className="h-4 w-4 bg-white rounded-full flex items-center justify-center">
                    <div className="w-3 h-3 rounded-full bg-black"> </div>
                  </div>
                  <p> Adventure Games </p>
                </div>
                <div className="flex items-center justify-start gap-4">
                  <div className="h-4 w-4 bg-white rounded-full flex items-center justify-center">
                    <div className="w-3 h-3 rounded-full bg-black"> </div>
                  </div>
                  <p> Strategy Games </p>
                </div>
                <div className="flex items-center justify-start gap-4">
                  <div className="h-4 w-4 bg-white rounded-full flex items-center justify-center">
                    <div className="w-3 h-3 rounded-full bg-black"> </div>
                  </div>
                  <p> Sports Games </p>
                </div>
                <div className="flex items-center justify-start gap-4">
                  <div className="h-4 w-4 bg-white rounded-full flex items-center justify-center">
                    <div className="w-3 h-3 rounded-full bg-black"> </div>
                  </div>
                  <p> Shooter Games </p>
                </div>
                <div className="flex items-center justify-start gap-4">
                  <div className="h-4 w-4 bg-white rounded-full flex items-center justify-center">
                    <div className="w-3 h-3 rounded-full bg-black"> </div>
                  </div>
                  <p> Fighting Games </p>
                </div>
              </div>
              <div className="mt-6">
                <a
                  href="/#gogogo"
                  behaviour="scroll"
                  className="py-3 px-12 bg-gradient-to-br from-red-700 via-red-800 to-blue-900 rounded-lg font-semibold text-white"
                >
                  Explore
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <img src={img1} alt="" className=" object-cover" />
        </div>
      </div>

      <div>
        <h1>Some gaming logos</h1>
      </div>

      <div id="gogogo" className="w-full px-4 max-w-6xl mx-auto">
        <div className="flex items-center flex-col-reverse md:flex-row justify-between text-white gap-12 ">
          <div>
            <img src={mmm} alt="" />
          </div>
          <div>
            <h2 className="text-4xl mb-4">Find out about us in history</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <div className="mt-6 flex items-center justify-start gap-8">
              <a
                href="tel:"
                className="py-3 px-8 bg-gradient-to-br from-red-700 via-red-800 to-blue-900 rounded-lg font-semibold text-white"
              >
                Give us a call
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-24 relative">
        <img src={img3} alt="" className=" object-cover" />
        <div className="w-full absolute md:top-16 top-4 px-4 flex items-center justify-center mx-auto">
          <div className="w-full absolute top-0 px-4 max-w-6xl mx-auto">
            <div className="flex items-center flex-col-reverse md:flex-row justify-between text-white gap-12 ">
              <div className="max-w-2xl">
                <h2 className="text-4xl mb-4">Live Games</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <div className="mt-6 md:flex hidden items-center justify-start gap-8">
                  <button
                    onClick={togglePlay}
                    className="py-3 px-8 bg-gradient-to-br from-red-700 via-red-800 to-blue-900 rounded-lg font-semibold text-white"
                  >
                    {play ? "Pause" : "Play"}
                  </button>
                </div>
              </div>
              <div className="md:block hidden">
                <video ref={vidRef} className="w-full md:h-[26rem] h-80">
                  <source src={vid1} type="video/mp4" /> Your browser does not
                  support the video tag
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-20 max-w-6xl mx-auto px-4">
        <h2 className="text-4xl mb-4 text-white text-start md:text-center ">
          Gamerz Favourite
        </h2>
        <div className="mt-12">
          <video autoPlay className="w-full h-[26rem]">
            <source src={vid2} type="video/mp4" /> Your browser does not support
            the video tag
          </video>
        </div>
      </div>

      <div className="mt-20 max-w-6xl mx-auto px-4">
        <h2 className="text-4xl mb-4 text-white text-start md:text-center ">
          Gallery
        </h2>
        <div className="mt-12 grid grid-cols-3 md:gap-5 gap-3">
          <div>
            <img src={pic1} alt="" className=" object-cover rounded-lg" />
          </div>
          <div>
            <img src={pic2} alt="" className=" object-cover rounded-lg" />
          </div>
        </div>
      </div>

      <div className="mt-20 max-w-6xl mx-auto px-4">
        <h2 className="text-4xl mb-4 text-white text-start md:text-center ">
          Upcoming Events!
        </h2>
        <div className="relative">
          <img src={img4} alt="" className=" object-cover rounded-lg" />
          <div className="w-full absolute md:top-16 top-4 px-4 flex items-center flex-col text-white justify-center mx-auto">
            <div className="mt-5 flex items-center justify-center gap-4">
              <div className="flex items-center justify-center gap-2">
                <p className="p-4 bg-white text-black font-semibold text-2xl rounded-lg">
                  {timeLeft?.days}
                </p>

                <p className="p-4 bg-white text-black font-semibold text-2xl rounded-lg">
                  : {timeLeft?.hours}
                </p>
                <p className="p-4 bg-white text-black font-semibold text-2xl rounded-lg">
                  : {timeLeft?.minutes}
                </p>
                <p className="p-4 bg-white text-black font-semibold text-2xl rounded-lg">
                  : {timeLeft?.seconds}
                </p>
              </div>
            </div>

            <div className="my-12 ">
              <h1 className=" text-4xl font-semibold sm:text-6xl mb-6">
                FC 24 Tournament
              </h1>

              <div className="w-full max-w-xl mx-auto">
                <div className="p-4 bg-white rounded-xl shadow-md pb-12 text-black w-full">
                  <div className="flex items-center justify-center">
                    <img
                      src={ggg}
                      alt=""
                      className=" object-cover rounded-full w-44 h-44"
                    />
                  </div>
                  <p className="text-lg uppercase font-semibold mt-6 text-center">
                    Register Below!
                  </p>

                  <div className="pt-6 px-4  mt-4">
                    <div>
                      <p className="text-start text-[12px] ml-2 mb-1 text-gray-500">
                        Full Name
                      </p>
                      <input
                        placeholder="Your name..."
                        className="w-full border-gray-500 outline-none border border-solid p-2 rounded-lg text-sm text-gray-600"
                      />
                    </div>
                    <div className="mt-6">
                      <p className="text-start text-[12px] ml-2 mb-1 text-gray-500">
                        Telephone
                      </p>
                      <input
                        placeholder="Your contact..."
                        className="w-full border-gray-500 outline-none border border-solid p-2 rounded-lg text-sm text-gray-600"
                      />
                    </div>
                    <div className="flex items-center justify-end">
                      <button className="py-2 px-12 bg-red-600 text-white mt-6 rounded-lg">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

// <div className="h-screen w-screen bg-black/50 absolute top-0 left-0">
//         <div className="w-full h-full flex items-center flex-col gap-12 text-center justify-center text-white p-4">
//           <div>
//             <h1 className="text-7xl text-5xl sm:text-6xl mb-6">
//               Gudlvn Gaming Tournament
//             </h1>
//             <p className="font-semibold tracking-wide">
//               Coming live this September 1, 2024
//             </p>
//             <div className="mt-5 flex items-center justify-center gap-4">
//               <h2 className="p-4 px-12 font-bold text-5xl bg-white rounded-lg shadow-md text-black text-4xl w-fit">
//                 {timeLeft?.days}
//                 <span className="text-[12px]">days</span> : {timeLeft?.hours} :
//                 {timeLeft?.minutes} : {timeLeft?.seconds}
//               </h2>
//             </div>
//           </div>
//          <div className="w-full max-w-xl">
//             <div className="p-4 bg-white rounded-xl shadow-md pb-12 text-black w-full">
//               <p className="text-lg uppercase mt-6">Register Below!</p>

//               <div className="py-12 px-4  mt-4">
//                 <div>
//                   <p className="text-start text-[12px] ml-2 mb-1 text-gray-500">
//                     Full Name
//                   </p>
//                   <input
//                     placeholder="Your name..."
//                     className="w-full border-gray-500 outline-none border border-solid p-2 rounded-lg text-sm text-gray-600"
//                   />
//                 </div>
//                 <div className="mt-6">
//                   <p className="text-start text-[12px] ml-2 mb-1 text-gray-500">
//                     Telephone
//                   </p>
//                   <input
//                     placeholder="Your contact..."
//                     className="w-full border-gray-500 outline-none border border-solid p-2 rounded-lg text-sm text-gray-600"
//                   />
//                 </div>
//                 <div>
//                   <button className="py-2 px-12 bg-red-600 text-white mt-6 rounded-lg">
//                     Submit
//                   </button>
//                 </div>
//               </div>
//             </div>
//             {/* <div>
//               <h1>Countdown to 20th September</h1>
//               <div>
//                 {timeLeft.days} days {timeLeft.hours} hours {timeLeft.minutes}
//                 minutes {timeLeft.seconds} seconds
//               </div>
//             </div> */}
//           </div>
//         </div>
//       </div>
